import { logoutCustomer } from 'api/driverama/orders/customer/customerAuth'
import { authCookies } from 'driverama-core/utils/auth'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { useEffect } from 'react'

const INTERNAL_PW_BASE_URL = '/orders/purchase-wizard'

export function useStockRouterEvents() {
  const router = useRouter()
  const impersonificationCookie = Cookies.get(authCookies.impersonification.key)

  useEffect(() => {
    function handleCompleteTransition() {
      NProgress.done()
    }

    async function removeImpersonificationSession() {
      await logoutCustomer()
      Cookies.remove(authCookies.impersonification.key)
    }

    const checkInitalExistingSession = () => {
      if (
        !router.pathname.includes(INTERNAL_PW_BASE_URL) &&
        !!impersonificationCookie
      ) {
        removeImpersonificationSession()
      }
    }

    async function handleRouteChange(nextRoute: string) {
      const isNextRouteWithinPW = nextRoute.includes(INTERNAL_PW_BASE_URL)

      if (!isNextRouteWithinPW) {
        removeImpersonificationSession()
      }

      NProgress.start()
    }

    checkInitalExistingSession()

    router.events.on('routeChangeStart', handleRouteChange)
    router.events.on('routeChangeError', handleCompleteTransition)
    router.events.on('routeChangeComplete', handleCompleteTransition)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
      router.events.off('routeChangeError', handleCompleteTransition)
      router.events.off('routeChangeComplete', handleCompleteTransition)
    }
    // eslint-disable-next-line
  }, [])
}
