import { useCallback, useState } from 'react'

export type Notification = {
  id: string
  title: string
  body: string
  createdAt?: string
}

export type NotificationContextType = {
  notifications: Notification[]
  openDropdown: boolean
  addNotifications: (notificationsToAdd: Notification | Notification[]) => void
  removeNotifications: (id?: string, removeAll?: boolean) => void
  openNotifications: () => void
  closeNotifications: () => void
  toggleNotifications: () => void
}

export function useNotifications(): NotificationContextType {
  const [openDropdown, setOpenDropdown] = useState(false)
  const [notifications, setNotifications] = useState<Notification[]>([])

  const openNotifications = useCallback(() => {
    setOpenDropdown(true)
  }, [])

  const closeNotifications = useCallback(() => {
    setOpenDropdown(false)
  }, [])

  const toggleNotifications = useCallback(() => {
    setOpenDropdown(prevState => !prevState)
  }, [])

  // adds notifications
  const addNotifications = useCallback(
    (notificationsToAdd: Notification | Notification[]) => {
      setNotifications(prevNotifications => {
        if (Array.isArray(notificationsToAdd)) {
          return [...prevNotifications, ...notificationsToAdd]
        }

        return [...prevNotifications, notificationsToAdd]
      })
    },
    []
  )

  // removes notifications
  const removeNotifications = useCallback(
    (id?: string, removeAll?: boolean) => {
      if (removeAll) {
        setNotifications([])

        return
      }

      if (id) {
        setNotifications(prevNotifications =>
          prevNotifications.filter(notification => notification.id !== id)
        )

        return
      }

      console.error('No id provided for removing the notification')
    },
    []
  )

  return {
    openDropdown,
    openNotifications,
    closeNotifications,
    notifications,
    addNotifications,
    removeNotifications,
    toggleNotifications
  }
}
