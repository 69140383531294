export const LINKS = {
  home: '/',
  login: '/login',
  logout: '/logout',
  repairs: '/repairs',
  marketing: '/marketing',
  orders: '/orders',
  sign: '/sign',

  userAccountTerms: '/user-account-terms',
  termsAndConditions: '/terms-and-conditions',
  buyingAuth: '/buying-wizard/auth/sign',
  signEmail: '/sign/email',
  signUpEmailPassword: '/sign/up/password',
  signInEmailPassword: '/sign/in/password',
  signVerify: '/sign/verify',
  signUpFinishAccount: '/sign/up/finish-account',
  signUpAlreadyExist: '/sign/up/already-exist',
  resetPasswordSuccess: '/reset-password/success',
  phoneNumberLost: '/sign/up/phone-number-lost',
  phoneNumberLostEmail: '/sign/up/phone-number-lost/email',
  phoneNumberLostCode: '/sign/up/phone-number-lost/code',
  phoneNumberLostNew: '/sign/up/phone-number-lost/new',
  profilePersonalDetails: '/profile/personal-details',
  profileOrderTracker: (orderId: string) => `/profile/orders/${orderId}`,
  privacyPolicy: '/privacy-policy',
  purchaseProcessInformation: '/purchase-process-information',
  profileOrders: '/profile/orders',
  profileChangePhone: '/profile/change-phone-number',
  resetPassword: '/reset-password',

  branch: (id: string) => `/branch/${id}`,

  serviceOrder: (stockNo: string) => `/repairs/${stockNo}/service-order`,
  carDetail: (carId: string) => `/stock/${carId}/detail`,
  carGallery: (stockNo: string) => `/stock/${stockNo}/gallery`,
  ecomWebWithMakeAndModel: (stockNo: string, make: string, model: string) =>
    `${process.env.NEXT_PUBLIC_WEB_URL}/en/cars/${make}/${model}/${stockNo}`,
  carReports: (id: string) => `/stock/${id}/reporting`,

  purchaseWizard: (orderId: string) => `/orders/purchase-wizard/${orderId}`,
  purchaseWizardOwner: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/owner`,
  purchaseWizardCountry: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/country`,
  purchaseWizardDelivery: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/delivery`,
  purchaseWizardDeliveryAddress: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/delivery/address`,
  purchaseWizardDeliveryLocation: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/delivery/location`,
  purchaseWizardWarranty: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/extended-warranty`,
  purchaseWizardOrderDetails: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/details`,
  purchaseWizardOrderSummary: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/summary`,
  purchaseWizardOrderConditions: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/conditions`,
  purchaseWizardPaymentInstructionsBankTransfer: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/payment/instructions/bank-transfer`,
  purchaseWizardPaymentInstructionsLoan: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/payment/instructions/loan`,
  purchaseWizardPayment: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/payment`,
  purchaseWizardRegistration: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/registration`,
  purchaseWizardRegistrationChecklist: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/registration/checklist`,
  purchaseWizardAuth: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/auth/sign`,
  purchaseWizardAuthResetPassword: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/auth/reset-password`,
  purchaseWizardAuthResetPasswordSuccess: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/auth/reset-password/success`,
  purchaseWizardAuthResetPasswordSetNew: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/auth/reset-password/set-new`,
  purchaseWizardAuthVerify: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/auth/verify`,
  purchaseWizardAuthFinishAccount: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/auth/up/finish-account`,
  purchaseWizardAuthAlreadyExist: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/auth/up/already-exist`,
  purchaseWizardAuthPhoneNumberLost: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/auth/phone-number-lost`,
  purchaseWizardAuthPhoneNumberLostEmail: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/auth/phone-number-lost/email`,
  purchaseWizardAuthPhoneNumberLostCode: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/auth/phone-number-lost/code`,
  purchaseWizardAuthPhoneNumberLostNew: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/auth/phone-number-lost/new`,
  purchaseWizardAuthEmail: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/auth/email`,
  purchaseWizardAuthEmailInPassword: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/auth/in/password`,
  purchaseWizardAuthEmailUpPassword: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/auth/up/password`,
  purchaseWizardAuthAssign: (orderId: string) =>
    `/orders/purchase-wizard/${orderId}/auth/assign`
} as const

export function mapsLink(lat: number, lng: number) {
  return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
}

export const BLOG_LINKS: Record<'registrationGuide', string> = {
  registrationGuide: '/blog/detail/how-to-register-a-car-a-step-by-step-guide'
}
