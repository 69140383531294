import {
  FilterModule,
  ManagerFilters,
  MarketingFilters,
  OrderFilters,
  ServiceOrderFilters,
  Sorting
} from '../Filters.utils'
import { initialState, State } from './FiltersState'

export type Action =
  | {
      type: 'SET_MODULE_FILTERS'
      payload: {
        module: keyof State
        filters: Partial<
          ManagerFilters | ServiceOrderFilters | MarketingFilters | OrderFilters
        >
      }
    }
  | {
      type: 'RESET_MODULE_FILTERS'
      payload: {
        module: keyof State
      }
    }
  | {
      type: 'SET_MODULE_SORTING'
      payload: {
        module: keyof State
        sorting: Partial<Sorting[FilterModule.ORDERS]>
      }
    }
  | {
      type: 'RESET_MODULE_SORTING'
      payload: {
        module: keyof State
      }
    }

export type Dispatch = (action: Action) => void

export function filtersReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_MODULE_FILTERS':
      return {
        ...state,
        [action.payload.module]: {
          filters: {
            ...state[action.payload.module].filters,
            ...action.payload.filters
          },
          sorting: state[action.payload.module].sorting
        }
      }

    case 'RESET_MODULE_FILTERS':
      return {
        ...state,
        [action.payload.module]: {
          filters: initialState[action.payload.module].filters,
          sorting: state[action.payload.module].sorting
        }
      }

    case 'SET_MODULE_SORTING':
      return {
        ...state,
        [action.payload.module]: {
          filters: state[action.payload.module].filters,
          sorting: {
            // TODO: remove comment after sorting by multiple columns is correctly working
            // ...state[action.payload.module].sorting,
            ...action.payload.sorting
          }
        }
      }

    case 'RESET_MODULE_SORTING': {
      return {
        ...state,
        [action.payload.module]: {
          filters: state[action.payload.module].filters,
          sorting: initialState[action.payload.module].sorting
        }
      }
    }
    default:
      return initialState
  }
}
