import {
  impersonificationLogin,
  impersonificationLogout
} from 'api/auth/impersonification'
import { components } from 'driverama-core/api/driverama/generated/customers'
import { URLS } from 'driverama-core/constants/api'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiFetcher } from 'driverama-core/utils/fetcher'

export async function loginCustomer(firebaseIdToken: string) {
  const response = await apiFetcher<
    components['schemas']['AuthenticationResponse']
  >(URLS.customersLogin, {
    method: HTTPMethod.POST,
    body: { token: firebaseIdToken }
  })

  const profile = response.json?.profile
  const access = response.json?.access

  await impersonificationLogin({
    refresh_token: access?.refreshToken,
    expires: access?.expiresInS
  })

  return { profile }
}

export async function signUpCustomer(
  firebaseIdToken: string,
  preferredLanguage: string
) {
  const response = await apiFetcher<
    components['schemas']['AuthenticationResponse']
  >(URLS.customersRegister, {
    method: HTTPMethod.POST,
    body: { token: firebaseIdToken, preferredLanguage }
  })

  const access = response.json?.access

  await impersonificationLogin({
    refresh_token: access?.refreshToken,
    expires: access?.expiresInS
  })
}

export async function logoutCustomer() {
  await impersonificationLogout()
}
