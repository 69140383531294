import { css, Global } from '@emotion/react'
import { NotificationContextProvider } from 'components/notifications/NotificationContextProvider'
import { LINKS } from 'constants/links'
import {
  AuthAppProps,
  AuthRedirectHead,
  AuthRedirectType
} from 'driverama-core/auth/next'
import { QueryContextWorkaround } from 'driverama-core/components/QueryContextWorkaround'
import { styledScrollbars } from 'driverama-core/styles/common'
import { global } from 'driverama-core/styles/global'
import { size } from 'driverama-core/styles/spacing'
import { color, font, variables } from 'driverama-core/styles/variables'
import { authCookies } from 'driverama-core/utils/auth'
import { createIntlClient } from 'driverama-core/utils/intl'
import { resolveRedirectDestination } from 'driverama-core/utils/page'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Hydrate } from 'react-query/hydration'
import { FilterProvider } from 'sections/filters/context/FiltersContext'
import { useStockRouterEvents } from 'utils/useRouterEvents'

function App({ Component, pageProps }: AuthAppProps) {
  const router = useRouter()
  const [queryClient] = useState(() => new QueryClient())

  useStockRouterEvents()

  const i18nClient = createIntlClient(pageProps.i18n)
  const authRedirectType = Component.auth?.type ?? AuthRedirectType.None

  return (
    <I18nextProvider i18n={i18nClient}>
      <QueryClientProvider client={queryClient} contextSharing>
        {authRedirectType !== AuthRedirectType.None && (
          <AuthRedirectHead
            type={authRedirectType}
            cookies={authCookies}
            destination={
              resolveRedirectDestination(Component.auth, router.query) ??
              LINKS.login
            }
          />
        )}
        <Hydrate state={pageProps.dehydratedState}>
          <QueryContextWorkaround>
            <Global
              styles={css`
                ${global.fonts};
                ${global.reset};
                ${global.nprogress};
                ${variables};

                body {
                  font-family: ${font('text')};
                  color: ${color('night-text')};
                }

                html {
                  scroll-padding-top: ${size(18)};
                }

                #nprogress {
                  color: ${color('night')};
                }

                ${styledScrollbars}

                .grecaptcha-badge {
                  visibility: hidden;
                }
              `}
            />
            <Head>
              <link rel="icon" href="/icon.svg" />
              <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0"
              />
            </Head>
            <NotificationContextProvider>
              <FilterProvider>
                <Component {...pageProps} />
              </FilterProvider>
            </NotificationContextProvider>
          </QueryContextWorkaround>
        </Hydrate>
      </QueryClientProvider>
    </I18nextProvider>
  )
}

export default App
