import { HTTPMethod } from 'driverama-core/constants/rest'
import { AuthSecret } from 'driverama-core/utils/auth'

export async function impersonificationLogin(payload: AuthSecret) {
  const res = await fetch('/api/auth/impersonification', {
    method: HTTPMethod.PUT,
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })

  if (!res.ok) {
    throw new Error(
      'AUTH:impersonificationLogin | Invalid response during impersonification login'
    )
  }

  return
}

export async function impersonificationLogout() {
  const res = await fetch('/api/auth/impersonification', {
    method: HTTPMethod.DELETE
  })

  if (!res.ok) {
    throw new Error(
      'AUTH:impersonificationLogout | Invalid response during impersonification logout'
    )
  }
}
