import { ReactNode } from 'react'
import { useNotifications } from './Notifications.utils'
import { NotificationContext } from './NotificationsContext'

type Props = {
  children: ReactNode
}

export function NotificationContextProvider({ children }: Props) {
  const notificationCtxValue = useNotifications()

  return (
    <NotificationContext.Provider value={notificationCtxValue}>
      {children}
    </NotificationContext.Provider>
  )
}
