import { SelectOption } from 'components/select/Select.utils'

import { SearchCarBody } from 'api/driverama/stock/carSearch'
import { SearchOrderSearchRequestBody } from 'api/driverama/stock/orders'
import { ServiceOrderQueryBody } from 'api/driverama/stock/serviceOrderSearch'
import { isNil } from 'driverama-core/utils/types'

export type ServiceOrderStatesType = NonNullable<
  ServiceOrderQueryBody['statesIncluding']
>[number]

// TODO remove when BE ready
export enum SourceEnum {
  DRIVERAMA = 'DRIVERAMA',
  AURES = 'AURES'
}

export enum SourceCountryEnum {
  SK = 'SK',
  CZ = 'CZ',
  PL = 'PL',
  DE = 'DE',
  NL = 'NL',
  HU = 'HU'
}

export enum ServiceOrderState {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLAIN = 'COMPLAIN',
  FINISHED = 'FINISHED'
}

export enum FilterModule {
  MARKETING = 'stockMarketing',
  REPAIRS = 'stockRepairs',
  MANAGER = 'stockManager',
  ORDERS = 'stockOrders'
}

export type ManagerFilters = SearchCarBody & { carIds?: string[] }
export type MarketingFilters = Pick<
  SearchCarBody,
  | 'makeIds'
  | 'modelIds'
  | 'states'
  | 'sellingStates'
  | 'syncedToAnyAggregator'
  | 'aggregatorRenewalBlacklisted'
  | 'vinOrStockNo'
> & { carIds?: string[] }
export type ServiceOrderFilters = ServiceOrderQueryBody

export type OrderFilters = SearchOrderSearchRequestBody

export type SortType = 'asc' | 'desc' | 'none'

export interface Sorting {
  [FilterModule.ORDERS]: Record<string, SortType>
}

export const getDefaultFilterMultiSelectValue = <T extends Array<string>>(
  filterOptions: SelectOption<T[number]>[],
  filterValue?: string[] | null
) => {
  if (isNil(filterValue)) {
    return null
  }

  const multipleValue = filterOptions.filter(option =>
    filterValue.includes(option.value)
  )
  //return only values which are set withing allFilters
  return multipleValue.length > 0 ? multipleValue : null
}

export const getDefaultSingleSelectValue = <T extends Array<string>>(
  filterOptions: SelectOption<T[number]>[],
  filterValue?: string | null
) => {
  if (isNil(filterValue)) {
    return null
  }

  const singleValue = filterOptions.find(option => option.value === filterValue)

  return singleValue ?? null
}
