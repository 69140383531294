import { CarTableDataSortKey } from 'api/driverama/stock/carSearch'
import { OrderSortKey } from 'api/driverama/stock/orders'
import { ServiceOrderSortKey } from 'api/driverama/stock/serviceOrderSearch'
import {
  FilterModule,
  ManagerFilters,
  MarketingFilters,
  OrderFilters,
  ServiceOrderFilters,
  SortType,
  SourceEnum
} from '../Filters.utils'

export type State = {
  [FilterModule.MANAGER]: {
    filters: Partial<ManagerFilters>
    sorting: Partial<Record<CarTableDataSortKey, SortType>>
  }
  [FilterModule.REPAIRS]: {
    filters: Partial<ServiceOrderFilters>
    sorting: Partial<Record<ServiceOrderSortKey, SortType>>
  }
  [FilterModule.MARKETING]: {
    filters: Partial<MarketingFilters>
    sorting: Partial<Record<CarTableDataSortKey, SortType>>
  }
  [FilterModule.ORDERS]: {
    filters: Partial<OrderFilters>
    sorting: Partial<Record<OrderSortKey, SortType>>
  }
}

export type SortKey = keyof (
  | Partial<Record<OrderSortKey, SortType>>
  | Partial<Record<CarTableDataSortKey, SortType>>
  | Partial<Record<ServiceOrderSortKey, SortType>>
)

export const initialState: State = {
  [FilterModule.MANAGER]: {
    filters: {
      sources: [SourceEnum.DRIVERAMA]
    },
    sorting: {
      'states.updatedAt': 'asc'
    }
  },
  [FilterModule.REPAIRS]: {
    filters: {
      statesIncluding: ['PENDING']
    },
    sorting: {
      stateUpdatedAt: 'desc'
    }
  },
  [FilterModule.MARKETING]: {
    filters: {},
    sorting: {
      'states.updatedAt': 'asc'
    }
  },
  [FilterModule.ORDERS]: {
    filters: {},
    sorting: {
      confirmedAt: 'desc'
    }
  }
}
